<template>
  <b-form class="card p-4 rounded form">
    <p class="font-weight-bold">Información</p>

    <div class="admin-config-wrapper">
      <z-input
        id="user-zubutId"
        v-model="mutableDriver.zubutId"
        label="Zubut ID"
        :disabled="!editMode"
      />

      <z-input
        id="user-muId"
        v-model="mutableDriver.muId"
        label="MU ID"
        :disabled="!editMode"
      />
      <div class="input-with-percent-sign">
        <span>%</span>
        <z-input
          id="km-earnings"
          v-model="mutableDriver.earningsResume.kmEarnings"
          label="Servicios por km"
          type="number"
          :disabled="!editMode"
        />
      </div>

      <div class="input-with-percent-sign">
        <span>%</span>
        <z-input
          id="hour-earnings"
          v-model="mutableDriver.earningsResume.hourEarnings"
          label="Servicios por hora"
          type="number"
          :disabled="!editMode"
        />
      </div>

      <div class="input-with-percent-sign">
        <span>%</span>
        <z-input
          id="package-earnings"
          v-model="mutableDriver.earningsResume.packageEarnings"
          label="Servicios por paquete"
          type="number"
          :disabled="!editMode"
        />
      </div>

      <div class="input-with-percent-sign">
        <span>%</span>
        <z-input
          id="batch-earnings"
          v-model="mutableDriver.earningsResume.batchEarnings"
          label="Servicios de guías"
          type="number"
          :disabled="!editMode"
        />
      </div>
    </div>
    <b-form-checkbox
      v-model="mutableDriver.alpha"
      class="mt-5"
      :disabled="!editMode"
      value="true"
      unchecked-value="false"
    >
      Mensajero de confianza
    </b-form-checkbox>
    <hr />
    <quick-message
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="hasError ? 'error' : 'success'"
    />
    <div
      v-if="$can(driverAccess.EDIT_DRIVER_ADMIN_DATA, driverAccess.moduleName)"
      class="actions mt-3 w-100 d-flex flex-column flex-sm-row "
    >
      <z-button
        v-show="!editMode"
        class="z-action w-auto"
        @click="editMode = !editMode"
      >
        Editar
      </z-button>
      <z-button
        v-show="editMode"
        variant="secondary"
        class="z-action w-auto mr-0 mr-sm-3"
        @click="cancelEdit"
      >
        Cancelar
      </z-button>
      <z-button
        v-show="editMode"
        class="z-action w-auto mt-3 mt-sm-0"
        @click="updateAdminProperties"
      >
        Guardar cambios
      </z-button>
    </div>
  </b-form>
</template>

<script>
import DriverAccess from "@/constants/access/drivers";
import Drivers from "@/services/drivers";

export default {
  name: "DriverDetailAdminConfig",

  props: {
    satwsRegistered: {
      type: Boolean,
      default: () => false
    },
    driver: {
      type: Object,
      default: () => ({
        earningsResume: {}
      })
    }
  },

  data() {
    return {
      driverAccess: DriverAccess,
      editMode: false,
      mutableDriver: null,
      driverDataWithoutEdit: null,
      hasError: false,
      isSuccess: false,
      message: ""
    };
  },

  computed: {
    hasMessage: {
      get() {
        return this.hasError || this.isSuccess;
      },
      set(newVal) {
        this.hasError = newVal;
        this.isSuccess = newVal;
      }
    }
  },

  watch: {
    driver: {
      immediate: true,
      handler(driver) {
        this.mutableDriver = { ...driver };
        this.driverDataWithoutEdit = {
          ...driver,
          earningsResume: { ...driver.earningsResume }
        };
      }
    }
  },

  methods: {
    updateAdminProperties() {
      const driverData = { id: this.mutableDriver.id };
      driverData.zubutId = this.mutableDriver.zubutId;
      driverData.muId = this.mutableDriver.muId;
      driverData.alpha = this.mutableDriver.alpha;
      try {
        this.verifyEarnings(this.mutableDriver.earningsResume);
        const {
          kmEarnings,
          hourEarnings,
          packageEarnings,
          batchEarnings
        } = this.mutableDriver.earningsResume;
        driverData.earnings = {
          kilometer: kmEarnings / 100,
          hour: hourEarnings / 100,
          package: packageEarnings / 100,
          batch: batchEarnings / 100
        };
        Drivers.updateAdminProperties({ driverData })
          .then(() => {
            this.isSuccess = true;
            this.message = "Cambio realizado exitosamente";
            this.editMode = !this.editMode;
            this.$emit("updated", true);
          })
          .catch(error => {
            this.$captureError(error);
            this.disabled = !this.disabled;
            this.hasError = true;
            this.message = "No se ha podido realizar el cambio";
          });
      } catch (err) {
        this.hasError = true;
        this.message = err.message;
      }
    },

    verifyEarnings(earnings) {
      Object.keys(earnings).forEach(val => {
        if (earnings[val] && (earnings[val] < 0 || earnings[val] > 100)) {
          throw new Error(
            "El porcentaje de ganancias debe estar entre 0 y 100%"
          );
        }
      });
    },

    cancelEdit() {
      this.editMode = !this.editMode;
      this.resetMutableDriver();
    },

    resetMutableDriver() {
      const { zubutId, muId, alpha } = this.driverDataWithoutEdit;

      this.mutableDriver.zubutId = zubutId;
      this.mutableDriver.muId = muId;
      this.mutableDriver.alpha = alpha;
      this.mutableDriver.earningsResume = {
        ...this.driverDataWithoutEdit.earningsResume
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.admin-config-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 25px 15px;
}
hr {
  border-color: $link-water;
}
.z-action {
  width: 100px;
  font-weight: 400;
  height: 40px;
}

.form {
  ::v-deep .custom-control-label {
    font-size: 12px;
  }
}

.custom-control.custom-checkbox {
  line-height: 21px;
}

.input-with-percent-sign {
  position: relative;
  span {
    position: absolute;
    top: 6px;
    right: 28px;
    z-index: 1;
    color: $comet;
  }
}

@media (min-width: 768px) {
  .admin-config-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
