<template>
  <b-row>
    <b-col cols="12" lg="6">
      <driver-detail-admin-config
        :driver="driver"
        :satws-registered="satwsRegistered"
        @updated="e => $emit('updated', e)"
      />
    </b-col>
    <b-col cols="12" lg="6" class="mt-3 mt-lg-0">
      <driver-detail-admin-verification :driver-id="driverId" />
    </b-col>
  </b-row>
</template>

<script>
import DriverDetailAdminConfig from "./DriverDetailAdminConfig";
import DriverDetailAdminVerification from "./DriverDetailAdminVerification";

export default {
  name: "DriverDetailAdmin",

  components: {
    DriverDetailAdminConfig,
    DriverDetailAdminVerification
  },

  props: {
    driver: {
      type: Object,
      default: () => {}
    },
    satwsRegistered: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    driverId() {
      return this.$route.params.id;
    }
  }
};
</script>
