<template>
  <div class="card rounded p-4">
    <p class="font-weight-bold">Documentación</p>
    <div>
      No hay documentos de verificación
    </div>
  </div>
</template>

<script>
export default {
  name: "DriverDetailAdminVerification"
};
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 1em;

  &:hover {
    background-color: rgba($royal-blue, 0.05);
    color: $zubut-blue;

    ::v-deep path {
      fill: $dodger-blue !important;
    }
  }
}
</style>
